import React, { useEffect, useRef, useState } from "react";
import { Avatar, EmojiPicker } from "react-activity-feed";
import { toast } from "react-toastify";
import styled from "styled-components";

import { events, logEvent } from "../../storage/analytics";
import { Image } from "../../assets/icons";
import { useFiles, useUser, useUsers } from "../../hooks";
import filesStorage from "../../storage/files";
import ImageInputList from "../common/ImageInputList";
import TextArea from "../TextArea";
import TextProgressRing from "../TextProgressRing";

interface FormProps {
  inline?: boolean;
  minheight?: string;
}

interface SparkleFormProps {
  submitText?: string;
  onSubmit: (text: string, images: string[]) => Promise<void>;
  className?: string;
  placeholder?: string;
  collapsedOnMount?: boolean;
  minHeight?: number;
  shouldFocus?: boolean;
  replyingTo?: string | null;
  sparkling: boolean;
}

export const MAX_CHARS = 280;
export const IMAGES_LIMIT = 3;

export default function SparkleForm({
  submitText = "Sparkle",
  onSubmit,
  className,
  placeholder,
  collapsedOnMount = false,
  minHeight = 120,
  shouldFocus = false,
  replyingTo = null,
  sparkling,
}: SparkleFormProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [expanded, setExpanded] = useState(!collapsedOnMount);
  const [text, setText] = useState("");
  const { user } = useUser();
  const { users } = useUsers();
  const [filteredUsers, setFilteredUsers] = useState<string[]>([]);
  const [isSelectingImages, setIsSelectingImages] = useState(false);
  const [mentionVisible, setMentionVisible] = useState(false);
  const { files, removeAllFiles, filesCount } = useFiles(IMAGES_LIMIT);

  useEffect(() => {
    if (filesCount) removeAllFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesCount]);

  useEffect(() => {
    if (shouldFocus && inputRef.current) inputRef.current.focus();
  }, [shouldFocus]);

  const actions = [
    {
      id: "image",
      Icon: Image,
      alt: "Image",
      onclick: () => setIsSelectingImages((value) => !value),
    },
    {
      id: "emoji-picker",
      Icon: EmojiPicker,
      alt: "Emoji",
    },
  ];

  const handleInputChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(value);

    const match = value.match(/@(\w*)$/);
    if (match) {
      const query = match[1].toLowerCase();
      setFilteredUsers(
        Object.keys(users).filter((u) => u.toLowerCase().startsWith(query))
      );
      setMentionVisible(true);
    } else setMentionVisible(false);
  };

  const handleEmojiSelect = (emojiData: any) => {
    const emoji = emojiData.native;

    if (!inputRef.current) return setText(text + emoji);

    const start = inputRef.current.selectionStart;
    const end = inputRef.current.selectionEnd;
    const newText =
      text.substring(0, start) + emoji + text.substring(end, text.length);
    setText(newText);

    inputRef.current.selectionStart = inputRef.current.selectionEnd =
      start + emoji.length;
  };

  const handleMentionClick = (username: string) => {
    const updatedText = text.replace(/@(\w*)$/, `@${username} `);
    setText(updatedText);
    setMentionVisible(false);
    inputRef?.current?.focus();
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!user) {
      toast.info("Login to sparkle");
      return;
    }

    if (text.length > MAX_CHARS) {
      toast.error("Sparkle cannot exceed " + MAX_CHARS + " characters");
      return;
    }

    if (textLimitExceeded)
      return alert("Sparkle cannot exceed " + MAX_CHARS + " characters");

    let imagesUrl: string[] = [];

    try {
      if (filesCount) imagesUrl = await filesStorage.saveFiles(files);
      await onSubmit(text, imagesUrl);

      removeAllFiles();
      logEvent(events.userInteraction.SPARKLE, { userId: user?._id });
      setText("");
      setIsSelectingImages(false);
      toast.success("Sparkle was a success");
    } catch (error) {
      toast.error("Sparkle couldn't be posted");
      if (filesCount) await filesStorage.deleteFiles(imagesUrl);
    }
  };

  const onClick = () => setExpanded(true);

  const isInputEmpty = !Boolean(text);
  const charsLeft = MAX_CHARS - text.length;
  const textLimitExceeded = charsLeft < 0;
  const isReplying = Boolean(replyingTo);

  return (
    <Container>
      {isReplying && expanded && (
        <span className="reply-to">
          Replying to <span className="reply-to--name">@{replyingTo}</span>
        </span>
      )}

      <Form
        minheight={minHeight + "px"}
        inline={!expanded}
        className={className}
        onSubmit={submit}
      >
        <figure className="user">
          <Avatar image={user?.profileImage} />
        </figure>
        <div className="input-section">
          <TextArea
            ref={inputRef}
            onChange={handleInputChange}
            placeholder={placeholder}
            value={text}
            onClick={onClick}
          />
          {mentionVisible && (
            <MentionsDropdown>
              {filteredUsers.map((username) => (
                <MentionItem
                  key={username}
                  onClick={() => handleMentionClick(username)}
                >
                  @{username}
                </MentionItem>
              ))}
            </MentionsDropdown>
          )}
          <div className="actions">
            {expanded &&
              actions.map((action) => {
                return (
                  <button
                    type="button"
                    key={action.id}
                    style={{ margin: "0 8px" }}
                    onClick={action?.onclick}
                  >
                    <action.Icon
                      size={19}
                      color="var(--theme-color)"
                      onSelect={handleEmojiSelect}
                    />
                  </button>
                );
              })}
            <div className="right">
              <TextProgressRing textLength={text.length} />
              {!isInputEmpty && <hr className="divider" />}
              <button
                type="submit"
                className="submit-btn"
                disabled={!text.trim() || sparkling}
              >
                {submitText}
              </button>
            </div>
          </div>
        </div>
      </Form>

      {isSelectingImages && <ImageInputList imagesLimit={IMAGES_LIMIT} />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  .reply-to {
    font-size: 14px;
    color: #888;
    display: flex;
    margin-left: 55px;
    margin-bottom: 10px;

    &--name {
      margin-left: 4px;
      color: var(--theme-color);
    }
  }
`;

const Form = styled.form<FormProps>`
  width: 100%;
  display: flex;
  align-items: ${({ inline }) => (inline ? "center" : "initial")};

  .user {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .input-section {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: ${({ inline }) => (inline ? "row" : "column")};
    align-items: ${({ inline }) => (inline ? "center" : "initial")};
    height: ${({ inline, minheight: minHeight }) =>
      inline ? "40px" : minHeight};

    .actions {
      margin-top: ${({ inline }) => (inline ? "0" : "auto")};
      display: flex;
      height: 50px;
      align-items: center;

      button {
        margin: 0 8px;
        &:disabled {
          opacity: 0.5;
        }
      }

      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }

      .divider {
        height: 30px;
        width: 2px;
        border: none;
        background-color: #444;
        border-radius: 5px;
        margin: 0 18px;
      }

      .submit-btn {
        background-color: var(--theme-color);
        padding: 8px 18px;
        color: white;
        border-radius: 30px;
        margin-left: auto;
        font-weight: bold;
        font-size: 16px;

        &:disabled {
          opacity: 0.6;
        }
      }
    }
  }
`;

const MentionsDropdown = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
`;

const MentionItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;
